import React, { useEffect, useState } from "react";
import { CssBaseline, Avatar, useTheme, useMediaQuery } from "@mui/material";
import Maps from "./Components/Maps";
import CustomTimeline from "./Components/CustomTimeline";
import { useLocation } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import BusinessIcon from "@mui/icons-material/Business";
import LanguageIcon from "@mui/icons-material/Language";
import ArticleIcon from "@mui/icons-material/Article";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import lottie_files from "./assets/icons/lottie_files.json";
import PinDropIcon from "@mui/icons-material/PinDrop";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

import { AppBar, Toolbar, Typography, Grid, Box, Paper } from "@mui/material";
import Lottie from "lottie-react";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  const [data, setData] = useState();
  const query = useQuery();
  const id = query.get("id");

  useEffect(() => {
    if (id) get_service_detail_for_customer(id);
  }, [id]);

  function get_service_detail_for_customer(id) {
    const requestOptions = {
      method: "POST",
      redirect: "follow",
    };
    fetch(
      `https://monprogress.samtwireless.com/public/api/get_service_detail_for_customer?id=${id}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          setData(result.data);
        } else {
          console.log("error", result.message);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {});
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {data ? (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar
              sx={{
                flexDirection: "row",
                alignItems: "center",
                padding: 1,
                backgroundColor: "#f90001",
                overflowX: "auto",
              }}
            >
              <Avatar
                src={data?.user?.avatar + "?v=" + Date.now()}
                alt={data?.user?.name}
                style={{
                  marginRight: "16px",
                  width: 45,
                  height: 45,
                  borderRadius: 100,
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    fontSize: {
                      xs: "1rem",
                      sm: "0.875rem",
                      md: "1.4rem",
                      textTransform: "capitalize",
                    },
                  }}
                >
                  <PersonIcon style={{ marginBottom: -3, marginLeft: -3 }} />{" "}
                  {data?.customer_name || "N/A"}
                </Typography>

                <Grid
                  container
                  sx={{ width: "100%", display: "flex", flexDirection: "row" }}
                >
                  <Grid container sx={{ flex: 1, flexWrap: "wrap" }}>
                    <Grid item xs={6} sm={3}>
                      <Typography
                        noWrap
                        variant="body2"
                        sx={{
                          width: { xs: 130, sm: 160, md: 250 },
                          fontSize: {
                            xs: "0.55rem",
                            sm: "0.575rem",
                            md: "0.8rem",
                          },
                        }}
                      >
                        <EmailIcon style={{ fontSize: 14, marginBottom: -3 }} />{" "}
                        {data?.email || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography
                        noWrap
                        variant="body2"
                        sx={{
                          width: { xs: 100, sm: 160, md: 250 },
                          fontSize: {
                            xs: "0.55rem",
                            sm: "0.575rem",
                            md: "0.8rem",
                            textTransform: "capitalize",
                          },
                        }}
                      >
                        <CallIcon style={{ fontSize: 14, marginBottom: -3 }} />{" "}
                        {data?.contact || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography
                        noWrap
                        variant="body2"
                        sx={{
                          width: { xs: 130, sm: 160, md: 250 },
                          fontSize: {
                            xs: "0.55rem",
                            sm: "0.575rem",
                            md: "0.8rem",
                            textTransform: "capitalize",
                          },
                        }}
                      >
                        <BusinessIcon
                          style={{ fontSize: 14, marginBottom: -3 }}
                        />{" "}
                        {data?.user?.name || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      {/* {data?.user?.website_link && ( */}
                      <Typography
                        noWrap
                        variant="body2"
                        sx={{
                          width: { xs: 150, sm: 160, md: 250 },
                          fontSize: {
                            xs: "0.55rem",
                            sm: "0.575rem",
                            md: "0.8rem",
                          },
                        }}
                      >
                        <LanguageIcon
                          style={{ fontSize: 14, marginBottom: -3 }}
                        />{" "}
                        {data?.user?.website_link || "N/A"}
                      </Typography>
                      {/* )} */}
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography
                        noWrap
                        variant="body2"
                        sx={{
                          width: { xs: 80, sm: 160, md: 250 },
                          fontSize: {
                            xs: "0.55rem",
                            sm: "0.575rem",
                            md: "0.8rem",
                            textTransform: "capitalize",
                          },
                        }}
                      >
                        <ArticleIcon
                          style={{ fontSize: 14, marginBottom: -3 }}
                        />{" "}
                        {data?.status || "N/A"}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <Typography
                        noWrap
                        variant="body2"
                        sx={{
                          width: { xs: 130, sm: 160, md: 250 },
                          fontSize: {
                            xs: "0.55rem",
                            sm: "0.575rem",
                            md: "0.8rem",
                            textTransform: "capitalize",
                          },
                        }}
                      >
                        <CalendarMonthIcon
                          style={{ fontSize: 14, marginBottom: -3 }}
                        />{" "}
                        {moment(data?.updated_at).format(
                          "DD-MMM-YYYY, h:mm A"
                        ) || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography
                        noWrap
                        variant="body2"
                        sx={{
                          width: { xs: 250, sm: 160, md: 250 },
                          fontSize: {
                            xs: "0.55rem",
                            sm: "0.575rem",
                            md: "0.8rem",
                            textTransform: "capitalize",
                          },
                        }}
                      >
                        <PinDropIcon
                          style={{ fontSize: 14, marginBottom: -3 }}
                        />{" "}
                        {data?.user?.address || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Typography
                        noWrap
                        variant="body2"
                        sx={{
                          width: { xs: 250, sm: 160, md: 250 },
                          fontSize: {
                            xs: "0.55rem",
                            sm: "0.575rem",
                            md: "0.8rem",
                            textTransform: "capitalize",
                          },
                        }}
                      >
                        <LocalShippingIcon
                          style={{ fontSize: 14, marginBottom: -3 }}
                        />{" "}
                        {data?.service?.name || "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Toolbar>
          </AppBar>

          <Grid container spacing={2} sx={{ padding: 2 }}>
            {data?.pickup_lat &&
              data?.pickup_lon &&
              data?.dropoff_lat &&
              data?.dropoff_lon && (
                <Grid item xs={12} sm={11.9} lg={8}>
                  <Box sx={{ height: "465px" }}>
                    {" "}
                    {/* Ensure the container has a height */}
                    <Maps data={data} />
                  </Box>
                </Grid>
              )}
            <Grid
              item
              xs={12}
              sm={11.9}
              lg={
                data?.pickup_lat &&
                data?.pickup_lon &&
                data?.dropoff_lat &&
                data?.dropoff_lon
                  ? 4
                  : 12
              }
            >
              <Paper elevation={3} sx={{ height: "100%" }}>
                {data && <CustomTimeline data={data} />}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Grid
          container
          spacing={2}
          sx={{ padding: 2 }}
          style={{
            height: "100vh",
            width: "100vw",
            backgroundColor: "rgba(0,0,0,0.01)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              height: 120,
              width: 350,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Lottie animationData={lottie_files} />
          </div>
          {/* <h2 style={{ color: "#f90001" }}>Please wait...</h2> */}
        </Grid>
      )}
    </>
  );
}

export default App;
